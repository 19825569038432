import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Navbar from 'common/source/containers/SolarNavbar';

import Footer from 'common/source/containers/Footer';
import MapaSolarView from 'common/source/containers/MapaSolarView';
import CTABanner from 'common/source/containers/CTABanner';

import SEO from '../components/SEO';
import config from '../../data/SiteConfig';

class MapaSolarPage extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO pageTitle={`Mapa Solar | ${config.siteTitle}`} path="/mapa-solar" />

          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Navbar background="blue" />
            <MapaSolarView />
            <CTABanner />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}
export default MapaSolarPage;
