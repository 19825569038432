import React from 'react';
// import Container from 'common/source/components/UI/Container';
import Box from 'reusecore/src/elements/Box';
import Image from 'reusecore/src/elements/Image';
import MapaSolarViewWrapper from './mapa-solar.style';
import Sudamerica from './img/sudamerica.png';
import Details from './img/solar_details.png';

const MapaSolarView = () => {
  return (
    <MapaSolarViewWrapper>
      <Box className="row">
        <Box className="col info">
          <Image src={Details} alt="UpSolar mapa solar" className="img-fluid" />
          <div className="about-info text-center">
            <h1>Mapa Solar Cono Sur</h1>
            <p className="lead">
              La geografía de nuestro continente es muy diversa tanto en forma
              como en energía.
              <br />
              Cuando hablamos de <i>energía solar</i>, nos referimos a la
              energía que se genera por medio de la radiación solar.
            </p>
            <p className="lead">
              A continuación encuentra un mapa del Cono Sur con un resumen de la
              radiación solar promedio anual. Las regiones más oscuras{' '}
              <em>–las más purpuras–</em> son las que reciben mayor radiación
              anual.
            </p>
            <p className="lead">
              Nuestras luminarias integradas cumplen con los requisitos para
              desempeñarse de forma óptima en el 99% del territorio. Las únicas
              excepciones destacables son el extremo sur, tanto por las
              temperaturas extremas (que alcanzan hasta los -30º <i>celsius</i>)
              como también por aquellas temporadas del año cuando los días con
              luz duran muy pocas horas.
            </p>
          </div>
        </Box>
        <Box className="col image">
          <Image src={Sudamerica} alt="UpSolar mapa solar" />
        </Box>
      </Box>
    </MapaSolarViewWrapper>
  );
};

export default MapaSolarView;
