import styled from 'styled-components';

const MapaSolarViewWrapper = styled.div`
  margin-top: 7.5rem;
  .row {
    display: flex;
    &.centered {
      align-items: center;
      text-align: center;
    }
    @media only screen and (max-width: 767px) {
      flex-flow: column;
    }
  }
  .col {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    @media only screen and (min-width: 767px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    &.info {
      margin-bottom: 3.5rem;
      @media (max-width: 767px) {
        margin-bottom: 7rem;
      }
      img {
        max-width: 100%;
        height: auto;
        max-width: 100%;
      }
    }
    &.image {
      text-align: center;
      padding-right: 0;
      max-width: 100%;
      height: auto;
      img {
        max-width: 100%;
        max-height: 88vh;
      }
    }
  }
  .about-info {
    padding: 0 3rem;
    &.text-center {
      text-align: center;
    }
    h1 {
      text-align: center;
      font-weight: 300;
      color: ${({ theme }) => theme.colors.primary};
    }
    p {
      line-height: 1.5;
      font-size: 1.1rem;
      font-weight: 300;
      color: ${({ theme }) => theme.colors.textColor};
    }
  }
`;

export default MapaSolarViewWrapper;
